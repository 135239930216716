import '~/wdyr';
import '~/polyfills';

import React from 'react';
import Modal from 'react-modal';
import { GoogleOAuthProvider } from '@react-oauth/google';

import '~/i18n';
import Header from '~/containers/Header';
// import MalaysiaModal from '~/components/MalaysiaModal';
import '@/application.scss';
import { createRoot } from 'react-dom/client';

const dom = document.getElementById('headerPageApp');

const root = createRoot(dom);

Modal.setAppElement(dom);

root.render(
  <GoogleOAuthProvider clientId={globalThis.HV.GOOGLE_OAUTH_CLIENT_ID}>
    <Header />
    {/* <MalaysiaModal /> */}
  </GoogleOAuthProvider>
);
